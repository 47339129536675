import React from "react"
import { graphql } from "gatsby"

import Helmet from "react-helmet"
import SEO from "../components/seo"

import PageHeaderSlim from '../components/pageHeaderSlim/pageHeaderSlim'
import ContactBody from '../components/contactBody/contactBody'
  
export const query = graphql`
  query ContactQuery {
    prismic {
      allContacts {
        edges {
          node {
            title
            location_map
            office_locations {
              location_title
              location_details
            }
            contact_details {
              details
            }
            _meta {
              uid
            }
          }
        }
      }
    }
  }
`

const Contact = ({ data }) => {
  const doc = data.prismic.allContacts.edges.slice(0,1).pop();
  if (!doc) return null;

  return (
    <React.Fragment>
      <SEO title="Contact" />
      <Helmet
        bodyAttributes={{
          class: 'bg-blue'
        }}
      />
      <div className="bg-blue t-color-white">
        <ContactBody doc={doc} />
      </div>
    </React.Fragment>
  )
}

export default Contact
