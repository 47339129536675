import React from "react"
import { RichText } from "prismic-reactjs"
import PropTypes from "prop-types"

import ResponsiveImage from '../responsiveImage/responsiveImage'
import Form from '../../components/form/form'

import styles from './contactBody.module.scss'

const ContactBody = ({ doc }) => {
  return (
    <React.Fragment>
      <div className={`cols-wrapper ${styles.header}`}>
        <div className="cols">
          <div className="col-12-md col-12-lg">
            <h1 className={`t-h2 t-color-white t-align-c`}>
              {RichText.asText(doc.node.title)}
            </h1>
          </div>
        </div>
      </div>
      <div style={{
        overflow: "auto"
      }}>
        <div className="section-slim-seamless">
          <div className="cols-wrapper t-align-c">
            <div className={`cols cols-wrap cols-center ${styles.contactDetails}`}>
              {doc.node.contact_details.map((block, index) => {
                if (block.details === null) {
                  return null
                }

                return (
                  <div className={`col-4-md col-4-lg ${styles.contactColumn}`} key={index}>
                    <div className="t-h3 text-body">
                      {RichText.render(block.details)}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

          <div className={styles.contactForm}>
            <Form
              name="contact"
            />
          </div>
        </div>

        <div className={`cols-wrapper ${styles.header}`}>
          <div className="cols">
            <div className="col-12-md col-12-lg">
              <h1 className={`t-h2 t-color-white t-align-c`}>
                Offices
              </h1>
            </div>
          </div>
        </div>

        {doc.node.location_map &&
          <div className="section-slim-seamless">
            <div className="cols-wrapper">
              <div className="cols cols-wrap">
                <div className={`col-12-md col-12-lg`}>
                  <ResponsiveImage
                    src={doc.node.location_map.url}
                    width={doc.node.location_map.dimensions.width}
                    height={doc.node.location_map.dimensions.height}
                    alt={doc.node.location_map.alt}
                    widths={['2200', '1500', '750']}
                    parentFit="width"
                    classes="image-block"
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {doc.node.office_locations &&
          <div className="section-slim-seamless">
            <div className="cols-wrapper">
              <div className="cols cols-wrap">
                {doc.node.office_locations.map((location, index) => {
                  if (location.location_title === null && location.location_details === null) {
                    return null
                  }

                  return (
                    <div className={`col-4-md col-4-lg ${styles.column} t-align-c`} key={index}>
                      <h3 className={`t-h3 section-title ${styles.locationTitle}`}>
                        {RichText.asText(location.location_title)}
                      </h3>
                      <div className="t-body-2 text-body">
                        {RichText.render(location.location_details)}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

ContactBody.propTypes = {
  doc: PropTypes.object
}

export default ContactBody